import { FunctionComponent } from 'react';

import { MessageBundle } from '@amzn/arb-tools';

import { PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms';
import { GridImage, PageSection } from 'src/components/common';
import { t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './ErrorTemplate.module.scss';

/**
 * TODO: Add unit tests: https://app.asana.com/0/1206972973144714/1207670044082979/f
 * TODO: Fix image and text positioning according to the mocks: https://app.asana.com/0/1206972973144714/1207670044082981/f
 * TODO: Update rendered image to be used from CRL, rather than fallback: https://sim.amazon.com/issues/ASX-227
 */
export const ErrorTemplate: FunctionComponent<{
  bundle: MessageBundle;
  errorPillLabel?: string;
  titleLabel: string;
  descriptionLabel: string;
  linkLabel?: string;
  altText?: string;
}> = ({ bundle, errorPillLabel, titleLabel, descriptionLabel, linkLabel, altText }) => {
  const { isSmallDesktop } = useDeviceSize();
  let titleElement;

  if (isSmallDesktop) {
    titleElement = <h2 className={styles.title}>{t(bundle, titleLabel)}</h2>;
  } else {
    titleElement = <h1 className={styles.title}>{t(bundle, titleLabel)}</h1>;
  }

  return (
    <PageSection>
      <div className={styles.notFoundContainer}>
        <PageSection className={styles.notFoundLeftContainer}>
          {errorPillLabel && <div className={styles.notFoundPill}>{t(bundle, errorPillLabel)}</div>}
          {titleElement}
          <p className={styles.description}>{t(bundle, descriptionLabel)}</p>
          {linkLabel && (
            <PrimaryButton
              label={t(bundle, linkLabel)}
              link="/"
              icon="chevronRight"
              buttonSize={isSmallDesktop ? PRIMARY_BUTTON_SIZE.Regular : PRIMARY_BUTTON_SIZE.Small}
            />
          )}
        </PageSection>
        <PageSection className={styles.notFoundRightContainer}>
          <GridImage imageUrl="" alt={t(bundle, altText)} />
        </PageSection>
      </div>
    </PageSection>
  );
};
