import { FunctionComponent } from 'react';
import { FieldError, useForm } from 'react-hook-form';

import { MessageBundle } from '@amzn/arb-tools';
import { RelationToAmazon } from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';
import { useBundle } from '@amzn/react-arb-tools';

import { PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms';
import { DropdownInput, DropdownInputMultiSelection } from 'src/components/form-elements';
import { COMMON_BUNDLE_NAME, ContactFormStrings, CreditsInterestFormQuestionInfo } from 'src/constants';
import { addRequiredLabelSymbol, t } from 'src/helpers';

import styles from './CreditsInterestForm.module.scss';

export interface CreditsInterestFormProps {
  contactBundle: MessageBundle;
  handleFormSubmit: (formValues: CreditsInterestFormValues) => void;
}

export enum CREDITS_INTEREST_FORM_KEYS {
  NAME = 'name',
  EMAIL = 'email',
  WEBSITE = 'website',
  JOB_TITLE = 'jobTitle',
  COMPANY = 'company',
  COMPANY_SIZE = 'companySize',
  RELATIONS_TO_AMAZON = 'relationsToAmazon',
  QUESTION_1 = 'question1',
  QUESTION_2 = 'question2',
  SUBQUESTION_2 = 'subquestion2',
  QUESTION_3 = 'question3',
  SUBQUESTION_3 = 'subquestion3',
}

export type CreditsInterestFormValues = {
  [CREDITS_INTEREST_FORM_KEYS.NAME]: string;
  [CREDITS_INTEREST_FORM_KEYS.EMAIL]: string;
  [CREDITS_INTEREST_FORM_KEYS.WEBSITE]: string;
  [CREDITS_INTEREST_FORM_KEYS.JOB_TITLE]: string;
  [CREDITS_INTEREST_FORM_KEYS.COMPANY]: string;
  [CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE]: string;
  [CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON]: RelationToAmazon[];
  [CREDITS_INTEREST_FORM_KEYS.QUESTION_1]: string;
  [CREDITS_INTEREST_FORM_KEYS.QUESTION_2]: string;
  [CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_2]: string;
  [CREDITS_INTEREST_FORM_KEYS.QUESTION_3]: string;
  [CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3]: string;
};

export const CreditsInterestForm: FunctionComponent<CreditsInterestFormProps> = ({
  contactBundle,
  handleFormSubmit,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<CreditsInterestFormValues>({ defaultValues: {} });

  const relationToAmazonOptions = [
    {
      value: 'SUPPLIER',
      label: 'Amazon supplier',
      description: 'My organization provides goods and services to Amazon',
    },
    {
      value: 'SELLING_PARTNER',
      label: 'Amazon third-party seller',
      description: 'My organization sells products directly to customers on Amazon',
    },
    { value: 'VENDOR', label: 'Amazon vendor', description: 'My organization sells products directly to Amazon' },
    {
      value: 'AWS_CUSTOMER',
      label: 'Amazon Web Services customer',
      description: 'My organization is a customer of Amazon Web Services',
    },
    { value: 'AMAZON_CUSTOMER', label: 'Amazon customer', description: 'My organization is a customer of Amazon' },
    {
      value: 'THE_CLIMATE_PLEDGE_SIGNATORY',
      label: 'The Climate Pledge signatory',
      description: 'My organization is a signatory of The Climate Pledge',
    },
    { value: 'OTHER', label: 'Other', description: '' },
  ];

  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const companySizeRanges = [
    { value: '0-100', label: `0 - 100 ${t(commonBundle, 'picker_employees')}` },
    { value: '101-1000', label: `101 - 1000 ${t(commonBundle, 'picker_employees')}` },
    { value: '1001-2000', label: `1,001 - 5,000 ${t(commonBundle, 'picker_employees')}` },
    { value: '5001-10000', label: `5,001 - 10,000 ${t(commonBundle, 'picker_employees')}` },
    { value: '10001+', label: `10,001 + ${t(commonBundle, 'picker_employees')}` },
  ];

  return (
    // TODO make subquestions visible based on question response
    // TODO add regex validations
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form}>
      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.NAME}>
          {t(contactBundle, ContactFormStrings.NAME_PLACEHOLDER_LABEL)}
          <input
            type="text"
            id={CREDITS_INTEREST_FORM_KEYS.NAME}
            {...register(CREDITS_INTEREST_FORM_KEYS.NAME, { required: true })}
          />
        </label>
        {errors.name && <span>{t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL)}</span>}
      </div>
      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.EMAIL}>
          {t(contactBundle, ContactFormStrings.EMAIL_PLACEHOLDER_LABEL)}
          <input
            type="email"
            id={CREDITS_INTEREST_FORM_KEYS.EMAIL}
            {...register(CREDITS_INTEREST_FORM_KEYS.EMAIL, { required: true })}
          />
        </label>
        {errors.email && <span>{t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL)}</span>}
      </div>
      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.JOB_TITLE}>
          {t(contactBundle, ContactFormStrings.JOB_TITLE_LABEL)}
          <input
            type="text"
            id={CREDITS_INTEREST_FORM_KEYS.JOB_TITLE}
            {...register(CREDITS_INTEREST_FORM_KEYS.JOB_TITLE, { required: true })}
          />
        </label>
        {errors[CREDITS_INTEREST_FORM_KEYS.JOB_TITLE] && (
          <span>{errors[CREDITS_INTEREST_FORM_KEYS.JOB_TITLE].message}</span>
        )}
      </div>

      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.COMPANY}>
          {t(contactBundle, ContactFormStrings.COMPANY_LABEL)}
          <input
            type="text"
            id={CREDITS_INTEREST_FORM_KEYS.COMPANY}
            {...register(CREDITS_INTEREST_FORM_KEYS.COMPANY, { required: true })}
          />
        </label>
        {errors[CREDITS_INTEREST_FORM_KEYS.COMPANY] && (
          <span>{errors[CREDITS_INTEREST_FORM_KEYS.COMPANY].message}</span>
        )}
      </div>

      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.WEBSITE}>
          {t(contactBundle, ContactFormStrings.WEBSITE_LABEL)}
          <input
            type="text"
            id={CREDITS_INTEREST_FORM_KEYS.WEBSITE}
            {...register(CREDITS_INTEREST_FORM_KEYS.WEBSITE, { required: true })}
          />
        </label>
        {errors[CREDITS_INTEREST_FORM_KEYS.WEBSITE] && (
          <span>{errors[CREDITS_INTEREST_FORM_KEYS.WEBSITE].message}</span>
        )}
      </div>

      <div>
        <DropdownInput
          label={addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.COMPANY_SIZE_LABEL))}
          control={control}
          name={CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE}
          options={companySizeRanges}
          errors={errors[CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE]}
          required={`${t(contactBundle, ContactFormStrings.COMPANY_SIZE_LABEL)}${ContactFormStrings.IS_MANDATORY}`}
          onChange={(value: string) => {
            setValue(CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE, value);
            clearErrors(CREDITS_INTEREST_FORM_KEYS.COMPANY_SIZE);
          }}
          isRadioVariant
        />
      </div>

      <div>
        <DropdownInputMultiSelection
          label={addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.AMAZON_RELATION_LABEL))}
          control={control}
          options={relationToAmazonOptions}
          sublabel={t(contactBundle, ContactFormStrings.SELECT_INFO)}
          errors={errors[CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON] as FieldError}
          {...register(CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON, {
            required: `${t(contactBundle, ContactFormStrings.AMAZON_RELATION_LABEL)}${ContactFormStrings.IS_MANDATORY}`,
          })}
          onChange={(value, isChecked) => {
            const currentSelection: RelationToAmazon[] =
              getValues(CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON) ?? [];

            if (isChecked && value && !currentSelection.includes(value)) {
              setValue(CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON, [...currentSelection, value]);
            } else if (!isChecked && value && currentSelection.includes(value)) {
              setValue(
                CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON,
                currentSelection.filter((type) => type !== value)
              );
            }
            clearErrors(CREDITS_INTEREST_FORM_KEYS.RELATIONS_TO_AMAZON);
          }}
        />
      </div>

      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.QUESTION_1}>
          {CreditsInterestFormQuestionInfo.QUESTION_1.text}
          <select
            id={CREDITS_INTEREST_FORM_KEYS.QUESTION_1}
            {...register(CREDITS_INTEREST_FORM_KEYS.QUESTION_1, { required: true })}
          >
            <option key={ContactFormStrings.YES_SELECTOR_OPTION} value={ContactFormStrings.YES_SELECTOR_OPTION}>
              {ContactFormStrings.YES_SELECTOR_OPTION}
            </option>
            <option key={ContactFormStrings.NO_SELECTOR_OPTION} value={ContactFormStrings.NO_SELECTOR_OPTION}>
              {ContactFormStrings.NO_SELECTOR_OPTION}
            </option>
          </select>
        </label>
        {errors[CREDITS_INTEREST_FORM_KEYS.QUESTION_1] && (
          <span>{errors[CREDITS_INTEREST_FORM_KEYS.QUESTION_1].message}</span>
        )}
      </div>

      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.QUESTION_2}>
          {CreditsInterestFormQuestionInfo.QUESTION_2.text}
          <select
            id={CREDITS_INTEREST_FORM_KEYS.QUESTION_2}
            {...register(CREDITS_INTEREST_FORM_KEYS.QUESTION_2, { required: true })}
          >
            <option key={ContactFormStrings.YES_SELECTOR_OPTION} value={ContactFormStrings.YES_SELECTOR_OPTION}>
              {ContactFormStrings.YES_SELECTOR_OPTION}
            </option>
            <option key={ContactFormStrings.NO_SELECTOR_OPTION} value={ContactFormStrings.NO_SELECTOR_OPTION}>
              {ContactFormStrings.NO_SELECTOR_OPTION}
            </option>
            <option
              key={ContactFormStrings.IN_PROCESS_SELECTOR_OPTION}
              value={ContactFormStrings.IN_PROCESS_SELECTOR_OPTION}
            >
              {ContactFormStrings.IN_PROCESS_SELECTOR_OPTION}
            </option>
          </select>
        </label>
        {errors[CREDITS_INTEREST_FORM_KEYS.QUESTION_2] && (
          <span>{errors[CREDITS_INTEREST_FORM_KEYS.QUESTION_2].message}</span>
        )}
      </div>

      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_2}>
          {CreditsInterestFormQuestionInfo.SUBQUESTION_2.text}
          <input
            type="text"
            id={CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_2}
            {...register(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_2, { required: true })}
          />
        </label>
        {errors[CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_2] && (
          <span>{errors[CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_2].message}</span>
        )}
      </div>

      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.QUESTION_3}>
          {CreditsInterestFormQuestionInfo.QUESTION_3.text}
          <select
            id={CREDITS_INTEREST_FORM_KEYS.QUESTION_3}
            {...register(CREDITS_INTEREST_FORM_KEYS.QUESTION_3, { required: true })}
          >
            <option key={ContactFormStrings.YES_SELECTOR_OPTION} value={ContactFormStrings.YES_SELECTOR_OPTION}>
              {ContactFormStrings.YES_SELECTOR_OPTION}
            </option>
            <option key={ContactFormStrings.NO_SELECTOR_OPTION} value={ContactFormStrings.NO_SELECTOR_OPTION}>
              {ContactFormStrings.NO_SELECTOR_OPTION}
            </option>
          </select>
        </label>
        {errors[CREDITS_INTEREST_FORM_KEYS.QUESTION_3] && (
          <span>{errors[CREDITS_INTEREST_FORM_KEYS.QUESTION_3].message}</span>
        )}
      </div>

      <div>
        <label htmlFor={CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3}>
          {CreditsInterestFormQuestionInfo.SUBQUESTION_3.text}
          <input
            type="text"
            id={CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3}
            {...register(CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3, { required: true })}
          />
        </label>
        {errors[CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3] && (
          <span>{errors[CREDITS_INTEREST_FORM_KEYS.SUBQUESTION_3].message}</span>
        )}
      </div>
      <div>
        <PrimaryButton
          label={t(contactBundle, ContactFormStrings.FORM_SUBMIT_BUTTON_LABEL)}
          icon="chevronRight"
          type="submit"
          buttonSize={PRIMARY_BUTTON_SIZE.Small}
        />
      </div>
    </form>
  );
};
