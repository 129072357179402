import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Control, FieldValues, Path, FieldError, useController } from 'react-hook-form';

import { CheckboxDescription, Icon } from 'src/components/atoms';
import { ContactFormStrings } from 'src/constants';

import styles from './DropdownInputMultiSelection.module.scss';

interface DropdownInputMultiSelectionProps<T extends FieldValues> {
  label: string;
  sublabel?: string;
  options: { value: string; label: string; description: string }[];
  errors?: FieldError | undefined;
  onChange: (value: any, isChecked: boolean) => void;
  name: Path<T>;
  control: Control<T>;
}

export const DropdownInputMultiSelection = <T extends FieldValues>({
  label,
  sublabel,
  options,
  errors,
  onChange,
  name,
  control,
}: DropdownInputMultiSelectionProps<T>) => {
  const { field } = useController({ name, control });
  const [isOpen, setIsOpen] = useState(false);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);
  const selectedOptionRef = useRef<HTMLParagraphElement>(null);
  const selectedValues = React.useMemo(() => (field.value as string[]) || [], [field.value]);
  const [selectedLabels, setSelectedLabels] = useState<Array<string | undefined>>();
  const [isOverflowed, setIsOverflowed] = useState(false);

  const checkOverflow = () => {
    const element = selectedOptionRef.current;
    if (element) {
      setIsOverflowed(element.scrollWidth > element.clientWidth);
    }
  };

  const toggleDropdown = () => {
    setIsOpen((wasOpened) => !wasOpened);
    checkOverflow();
  };

  const handleOptionChange = (option: { value: string; label: string }, isChecked: boolean) => {
    setSelectedLabels((prevSelectedLabels) => {
      const updatedLabels = prevSelectedLabels ? [...prevSelectedLabels] : [];
      if (isChecked) {
        updatedLabels.push(option.label);
      } else {
        const index = updatedLabels.indexOf(option.label);
        if (index >= 0) {
          updatedLabels.splice(index, 1);
        }
      }
      return updatedLabels;
    });

    // Update selected values
    const newSelectedValues = isChecked
      ? [...selectedValues, option.value]
      : selectedValues.filter((item) => item !== option.value);

    field.onChange(newSelectedValues);
    onChange(option.value, isChecked);
  };

  useEffect(() => {
    checkOverflow();

    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [selectedValues]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const handleLastOptionBlur = (event: React.FocusEvent<HTMLButtonElement>) => {
    const { relatedTarget } = event;
    if (!dropdownButtonRef.current?.contains(relatedTarget) && !optionsRef.current?.contains(relatedTarget)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideDropdown =
        dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target as Node);
      const isClickOutsideOptions = optionsRef.current && !optionsRef.current.contains(event.target as Node);
      if (isClickOutsideDropdown && isClickOutsideOptions) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={classNames(styles.dropdownInputMultiSelectionContainer, errors && styles.error)}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      {sublabel && (
        <div className={styles.subLabelContainer}>
          <Icon name="info" className={classNames(styles.subLabelIcon, errors && styles.error)} />
          <p className={classNames(styles.subLabel, errors && styles.error)}>{sublabel}</p>
        </div>
      )}
      <button
        className={styles.dropdown}
        onClick={toggleDropdown}
        ref={dropdownButtonRef}
        type="button"
        data-testid="dropdown-multiple-select-input"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <p className={styles.selectedOption} data-testid="dropdown-selected-label" ref={selectedOptionRef}>
          {selectedLabels && selectedLabels.length > 0
            ? selectedLabels.join(', ')
            : `${ContactFormStrings.SELECT_OPTION}`}
        </p>
        <div className={styles.leftSideWrapper}>
          {(isOverflowed || selectedValues.length >= 2) && (
            <span className={styles.counter}>{` (${selectedValues.length})`}</span>
          )}
          <Icon className={classNames(styles.icon, isOpen && styles.open)} name="chevronRight" />
        </div>
      </button>
      {isOpen && (
        <ul className={styles.optionsContainer} role="menu" ref={optionsRef}>
          {options.map((option, index) => (
            <li key={option.label}>
              <CheckboxDescription
                className={classNames(styles.option, index && 'isFirst')}
                label={option.label}
                description={option.description}
                onChange={(isChecked) => handleOptionChange(option, isChecked)}
                error={Boolean(errors)}
                checked={selectedValues.includes(option.value)}
                onBlur={index === options.length - 1 ? handleLastOptionBlur : undefined}
              />
            </li>
          ))}
        </ul>
      )}
      {errors && (
        <div className={styles.errorWrapper}>
          <Icon name="error" className={styles.errorIcon} />
          <p className={styles.errorMessage}>{errors.message}</p>
        </div>
      )}
    </div>
  );
};
