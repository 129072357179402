import { FunctionComponent, ImgHTMLAttributes, useState } from 'react';

import { getFallbackImage } from 'src/images/fallback/fallbackProvider';
import { emitCountMetric, emitTimeMetric, logError, logger } from 'src/logger';
import { IMAGE_LOAD_TIME, IMAGE_LOAD_SUCCESS, IMAGE_LOAD_FAILURE } from 'src/logging-helpers/metrics-constants';

interface AsxImageFallbackProps extends ImgHTMLAttributes<any> {
  fallback?: string;
}

/**
 * Component for images on Amazon Sustainability Exchange (ASX). The fallback image will be returning if the src image
 * is not working. Metrics are emitted for image load and image latency.
 *
 * @param fallback fallback image
 * @param src src image
 * @param alt alt text for image
 * @param props any other props
 */
// TODO make src required: https://sim.amazon.com/issues/ASX-280
export const AsxImage: FunctionComponent<AsxImageFallbackProps> = ({
  src,
  alt,
  fallback = getFallbackImage(),
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);
  const handleLoad = () => {
    const entry = performance.getEntriesByType('resource').find((e) => e.name === src);
    if (entry) {
      const { duration } = entry;
      emitTimeMetric(IMAGE_LOAD_TIME, duration);
      emitCountMetric(IMAGE_LOAD_SUCCESS, 1);
      logger.info(`Loaded image successfully in duration ${duration} with src ${src} and fallback ${fallback}`);
    }
  };
  const handleError = (error: any) => {
    setImgSrc(fallback);
    emitCountMetric(IMAGE_LOAD_FAILURE, 1);
    logError('AsxImage', `Unable to load image with src ${src}. Loaded fallback ${fallback} instead`, error);
  };
  return (
    <img
      src={imgSrc || fallback}
      onError={(error) => handleError(error)}
      onLoad={handleLoad}
      alt={alt ?? ''}
      {...props}
    />
  );
};
