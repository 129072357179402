import { useMemo } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { CONTACT_FORM_PAGE_BUNDLE_NAME } from 'src/constants';
import imageFallback1 from 'src/images/fallback/images-background/image-1.png';
import imageFallback2 from 'src/images/fallback/images-background/image-2.png';
import imageFallback3 from 'src/images/fallback/images-background/image-3.png';
import imageFallback4 from 'src/images/fallback/images-background/image-4.png';

// TODO: Replace with actual resources when available
// https://sim.amazon.com/issues/ASX-227
export const useThankYouData = () => {
  const [contactFormBundle] = useBundle(CONTACT_FORM_PAGE_BUNDLE_NAME);

  return useMemo(
    () => ({
      contactFormBundle,
      images: [
        { src: imageFallback1, alt: 'image top left' },
        { src: imageFallback2, alt: 'image top right' },
        { src: imageFallback3, alt: 'image bottom left' },
        { src: imageFallback4, alt: 'image bottom right' },
      ],
      cards: [
        {
          image: imageFallback1,
          alt: 'image top left',
          tagline: 'tagline',
          copy: 'Identify solutions to help lower your carbon footprint',
          link: 'href',
        },
        {
          image: imageFallback1,
          alt: 'image top left 2',
          title: 'Carbon-free Energy',
          copy: 'Identify solutions to help lower your carbon footprint',
          link: 'href 2',
        },
        {
          image: imageFallback1,
          alt: 'image top left 3',
          tagline: 'tagline 3',
          copy: 'Identify solutions to help lower your carbon footprint',
          link: 'href 3',
        },
      ],
      subtitle: 'Keep exploring',
    }),
    [contactFormBundle]
  );
};
