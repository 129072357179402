import classNames, { type Argument } from 'classnames';
import { useState, type KeyboardEvent as ReactKeyboardEvent, type ReactElement } from 'react';

import { Icon } from 'src/components/atoms';

import styles from './CheckboxDescription.module.scss';

interface CheckboxDescriptionProps {
  className?: Argument;
  label?: string;
  description?: string;
  error?: boolean;
  onChange(isChecked: boolean): void;
  checked?: boolean;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
}

function isEnterOrSpacePressed(event: KeyboardEvent | ReactKeyboardEvent): boolean {
  return event.key === 'Enter' || event.key === ' ';
}

export function CheckboxDescription({
  className,
  error,
  label,
  description,
  onChange,
  checked = false,
  onBlur,
}: CheckboxDescriptionProps): ReactElement {
  const [isChecked, setIsChecked] = useState(checked);

  const onHandleCheck = (): void => {
    onChange(!isChecked);
    setIsChecked((wasChecked) => !wasChecked);
  };

  const onHandleKeyDown = (event: ReactKeyboardEvent<Element>): void => {
    if (isEnterOrSpacePressed(event)) {
      event.preventDefault();
      onHandleCheck();
    }
  };

  return (
    <button
      data-testid={`checkbox-description-${label}`}
      className={classNames(styles.checkboxDescription, error && styles.error, className)}
      onClick={onHandleCheck}
      onBlur={onBlur}
      tabIndex={-1}
      type="button"
    >
      <div className={styles.checkbox}>
        <input
          className={styles.input}
          type="checkbox"
          checked={isChecked}
          onChange={onHandleCheck}
          onKeyDown={onHandleKeyDown}
          aria-describedby={description ? `description-${label}` : undefined}
          data-testid={`checkbox-description-input-${label}`}
        />
        <span className={styles.checkmark}>
          <Icon className={styles.icon} name="check" aria-hidden />
        </span>
        <span className={styles.label} aria-hidden>
          {label}
        </span>
      </div>
      {description && (
        <p id={`description-${label}`} className={styles.description}>
          {description}
        </p>
      )}
    </button>
  );
}
