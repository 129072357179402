import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';

import { Icon } from 'src/components/atoms';

import styles from './RadioGroup.module.scss';

export type RadioOption = {
  value: string;
  label: string;
  additionalLabel?: string;
};

interface RadiGroupProps<T extends FieldValues> {
  ariaId: string;
  control: Control<T>;
  name: Path<T>;
  options: RadioOption[];
  required?: string;
  title: string;
  onChange?: (value: string) => void;
}

export const RadioGroup = <T extends FieldValues>({
  ariaId,
  control,
  name,
  options,
  required,
  title,
  onChange,
}: RadiGroupProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required ?? false,
    },
  });

  const handleOptionChange = (option: RadioOption) => {
    field.onChange(option.value as PathValue<T, Path<T>>);
    if (onChange) onChange(option.value);
  };

  return (
    <div className={styles.radioGroup} role="radiogroup" aria-labelledby={ariaId}>
      <p className={styles.title} id={ariaId}>
        {title + (required ? ' *' : '')}
      </p>
      <div className={styles.optionsContainer}>
        {options.map((option) => (
          <div className={styles.option} key={option.value}>
            <input
              id={option.value}
              className={styles.radio}
              type="radio"
              name="radio-button"
              data-testid="option"
              onChange={() => handleOptionChange(option)}
            />
            <label
              className={styles.label}
              htmlFor={option.value}
              aria-label={option.additionalLabel ? `${option.label}, ${option.additionalLabel}` : option.label}
            >
              {option.label}
            </label>
            {option.additionalLabel && (
              <span className={styles.additionalLabel} data-testid="additional-label">
                {option.additionalLabel}
              </span>
            )}
          </div>
        ))}
      </div>

      {error && (
        <div className={styles.errorWrapper}>
          <Icon name="error" className={styles.errorIcon} />
          <p className={styles.errorMessage}>{error.message}</p>
        </div>
      )}
    </div>
  );
};
