import classNames from 'classnames';
import { FieldError } from 'react-hook-form';

import { CheckboxDescription, Icon } from 'src/components/atoms';
import { TextInput } from 'src/components/form-elements';
import { AdditionalFormInputProps } from 'src/interfaces/AdditionalFormInputProps';

import styles from './CheckboxDescriptionGroup.module.scss';

interface CheckboxDescriptionGroupProps<T> {
  label: string;
  options: {
    value: T;
    checkboxLabel: string;
    checkboxDescription: string;
  }[];
  onChange: (value: T, isChecked: boolean) => void;
  errors?: FieldError;
  id: string;
  testId: string;
  sublabel?: string;
  showAdditionalInput?: boolean;
  additionalInputProps?: AdditionalFormInputProps;
  initialValues?: Array<T>;
}

export const CheckboxDescriptionGroup = <T,>({
  label,
  options,
  onChange,
  errors,
  id,
  testId,
  sublabel,
  showAdditionalInput,
  additionalInputProps,
  initialValues,
}: CheckboxDescriptionGroupProps<T>) => (
  <div className={styles.checkboxDescriptionGroupContainer} data-testid={testId}>
    <fieldset>
      <legend className={classNames(styles.label, errors && styles.error)} id={id}>
        {label}
      </legend>
      {sublabel && (
        <div className={styles.subLabelContainer}>
          <Icon name="info" className={classNames(styles.subLabelIcon, errors && styles.error)} />
          <p className={classNames(styles.subLabel, errors && styles.error)}>{sublabel}</p>
        </div>
      )}
      <ul className={styles.checkboxGroup} aria-labelledby={id}>
        {options.map(({ value, checkboxLabel, checkboxDescription }, index) => (
          <li key={checkboxLabel}>
            <CheckboxDescription
              className={classNames(index && 'isFirst')}
              label={checkboxLabel}
              description={checkboxDescription}
              onChange={(isChecked) => onChange(value, isChecked)}
              error={Boolean(errors)}
              checked={initialValues?.includes(value)}
            />
          </li>
        ))}
        {showAdditionalInput && additionalInputProps && (
          <div className={styles.textInputContainer}>
            <TextInput
              label={additionalInputProps.label}
              inputType="text"
              id={additionalInputProps.id}
              value={additionalInputProps.additionalInputValue}
              {...additionalInputProps.register(additionalInputProps.id, additionalInputProps.registerOptions)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                additionalInputProps.onChange(event.target.value)
              }
              errors={additionalInputProps.error}
            />
          </div>
        )}
      </ul>
      {errors && (
        <div className={styles.errorWrapper}>
          <Icon name="error" className={styles.errorIcon} />
          <p className={styles.errorMessage}>{errors.message}</p>
        </div>
      )}
    </fieldset>
  </div>
);
