import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { PageSection, SmallBanner } from 'src/components/common';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { getImageRoot, t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

export const ClimatePledgeSection: FunctionComponent = () => {
  const [bundle] = useBundle(COMMON_BUNDLE_NAME);
  const { isSmallDesktop } = useDeviceSize();
  return (
    <PageSection theme="grey-tertiary">
      <SmallBanner
        theme={SECTION_THEME.Light}
        heading={t(bundle, 'climatePledge_heading')}
        cta={{
          label: t(bundle, 'climatePledgeButton_label'),
          href: 'https://www.theclimatepledge.com/',
        }}
        image={{
          url: `${getImageRoot(isSmallDesktop)}/TCPglobalcomponent-2.png`,
          alt: t(bundle, 'footerTemplateImage_alt'),
        }}
        logo
      />
    </PageSection>
  );
};
