import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';

import { ModalWrapper } from 'src/components/common';
import { RadioGroup } from 'src/components/form-elements';

type HmdFormValues = {
  rating: string;
};

export const HmdDemoPage: FunctionComponent = () => {
  const { control, clearErrors } = useForm<HmdFormValues>({
    reValidateMode: 'onSubmit',
  });

  return (
    <ModalWrapper isVisible close={() => null}>
      <RadioGroup
        ariaId="hmdRadioButtons"
        title="1. How satisfied are you with this content?"
        options={[
          { value: '1', label: '1', additionalLabel: 'Very unsatisfied' },
          { value: '2', label: '2' },
          { value: '3', label: '3', additionalLabel: 'Neutral' },
          { value: '4', label: '4' },
          { value: '5', label: '5', additionalLabel: 'Very satisfied' },
        ]}
        name="rating"
        control={control}
        onChange={() => {
          clearErrors();
        }}
        required="Required field"
      />
    </ModalWrapper>
  );
};
