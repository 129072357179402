import { DisplayType } from '@amzn/coral_com-amazon-sssapinterface-model';

import { WEBLAB_TREATMENT, getTreatment, WEBLAB_NAME } from 'src/clients/weblab-client';

export const getLibraryDomainRoot = () => `https://library.exchange.aboutamazon.com`;

/**
 * This will provide the prefix for an image url in the content resource library.
 * @param isSmallDesktop whether this is a small desktop or not
 */
export const getImageRoot = (isSmallDesktop: boolean): string => {
  const imageVariant = isSmallDesktop ? 'desktop' : 'mobile';
  return `https://library.exchange.aboutamazon.com/locale/en-us/resource-type/image/variation/${imageVariant}`;
};
/**
 * The display type (i.e. desktop vs mobile) based on the screen width size
 * @param isSmallDesktop whether this is a small desktop or not
 */
export const getDisplayType = (isSmallDesktop: boolean) => (isSmallDesktop ? DisplayType.STANDARD : DisplayType.MOBILE);

export const shouldRenderFluidPageSection = (): boolean =>
  WEBLAB_TREATMENT.T1 === getTreatment(WEBLAB_NAME.ASX_FLUID_PAGE_SECTION);

export const shouldRenderAccessibleSlider = (): boolean =>
  WEBLAB_TREATMENT.T1 === getTreatment(WEBLAB_NAME.ASX_CARDS_SLIDER_ACCESSIBILITY);

export const shouldRenderHmdDemoPage = (): boolean => WEBLAB_TREATMENT.T1 === getTreatment(WEBLAB_NAME.ASX_HMD_LAUNCH);
